@use "../../../../assets/styles/scss/variables" as v;
@use "../../../../assets/styles/scss/mixins" as m;

.who-we-are {
    p {
        text-align: justify;
    }

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .who-we-are-content {
        @include m.flexbox(column, space-between, center, 1rem);

        .who-we-are-info {
            > p {
                margin-bottom: 5rem;
            }

            h2 {
                font-size: 2.5rem;
                color: v.$color2;
                margin-bottom: 2rem;
            }
        }
    }
}
