@use "../../../assets/styles/scss/variables" as v;
@use "../../../assets/styles/scss/mixins" as m;

.dashboard-card {
    box-shadow: 0 0 5px v.$color3;
    border-radius: 5px;
    cursor: pointer;
    font-style: italic;
    @include m.flexbox(row, flex-start, stretch);

    .icon {
        flex: 1;
        background-color: v.$color6;
        @include m.flexbox(row, center, center);

        svg {
            font-size: 3rem;
        }
    }

    .content {
        flex: 4;
        text-align: center;

        .title {
            text-transform: uppercase;
            color: v.$color4;
            background-color: v.$color3;
            padding: 0.5rem 0;

            h3 {
                font-size: 1.3rem;
            }
        }

        .item {
            padding: 0.5rem 0;

            p {
                margin: 0;
                font-size: 1.2rem;
            }
        }
    }
}
