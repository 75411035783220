@use "../../../../assets/styles/scss/variables" as v;
@use "../../../../assets/styles/scss/mixins" as m;

.admin-contact-message-details-page {
    .content {
        table {
            width: 100%;

            td {
                padding: 1.5rem 0;
                font-size: 1.1rem;

                &:nth-child(1) {
                    width: 20%;
                    font-weight: 600;
                    text-transform: uppercase;
                    color: v.$color1;
                }

                &:nth-child(2) {
                    width: 1%;
                }

                &:nth-child(3) {
                    width: 79%;
                }
            }
        }
    }

    .buttons-container {
        margin-top: 2rem;
        text-align: end;

        button.btn {
            text-transform: uppercase;

            &:nth-child(1) {
                @include m.button(v.$color1, v.$color4, v.$color6, v.$color1);
            }

            &:nth-child(2) {
                @include m.button(v.$color2, v.$color4, v.$color6, v.$color1);
            }
        }
    }
}
