@use "../../../assets/styles/scss/variables" as v;
@use "../../../assets/styles/scss/mixins" as m;

$breakpoint: 992px;

.admin-sidebar {
    @media (min-width: $breakpoint) {
        align-items: flex-start !important;
        position: fixed !important;
        top: 0;
        left: 0;
        bottom: 0;
    }

    .container {
        @media (min-width: $breakpoint) {
            flex-direction: column;
        }
    }

    .navbar-collapse {
        width: 100%;
    }

    .navbar-brand {
        img {
            @media (max-width: 450px) {
                width: 200px;
            }
        }
    }

    .navbar-nav {
        width: 100%;
        padding: 1.5rem;
        @include m.flexbox(column, $gap: 1rem);

        @media (min-width: $breakpoint) {
            flex-direction: column !important;
        }

        .nav-link {
            color: v.$color1;

            &:hover {
                color: v.$color2;
            }

            &.active {
                color: v.$color2;
            }
        }
    }

    .navbar-toggler {
        background-color: v.$color1;
    }
}
