@use "../../../assets/styles/scss/variables" as v;
@use "../../../assets/styles/scss/mixins" as m;

.loading-page {
    background-color: v.$color2;
    width: 100%;
    height: 100%;
    @include m.flexbox(row, center, center, 0);
    z-index: 1;

    img {
        width: 200px;
        height: auto;
        z-index: 2;
    }

    .spinner-border {
        width: 15rem;
        height: 15rem;
        position: absolute;
        background-color: v.$color4;
    }
}
