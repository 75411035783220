@use "../../../../assets/styles/scss/variables" as v;
@use "../../../../assets/styles/scss/mixins" as m;

.contact-info {
    @include m.reset-list;

    .icons {
        color: v.$color1;

        &:hover {
            color: v.$color2;
        }

        svg {
            font-size: 1.2rem;
        }
    }

    a {
        text-decoration: none;
        color: v.$color1;

        &:hover {
            color: v.$color2;
        }
    }
}
