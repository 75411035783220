@use "../../../assets/styles/scss/variables" as v;
@use "../../../assets/styles/scss/mixins" as m;

.vehicle-details {
    .panel-title {
        @include m.flexbox(row, space-between, center, 0);
    }

    .card {
        img {
            border-radius: 2rem;
        }
    }

    ul {
        @include m.reset-list;
        @include m.flexbox(row, space-between, center, 2rem);
        flex-wrap: wrap;
        text-align: center;
        margin-top: 2rem;

        li {
            flex: 0 0 150px;
            @include m.flexbox(column, flex-start, center, 1rem);
            color: v.$color2;

            @media (max-width: 400px) {
                flex-basis: 100px;
            }

            svg {
                font-size: 3rem;
                color: v.$color1;
            }
        }
    }
}
