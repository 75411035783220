@use "../../../assets/styles/scss/variables" as v;
@use "../../../assets/styles/scss/mixins" as m;

.admin-vehicles-page {
    @include m.flexbox(column, flex-start, center, 3rem);

    a[role="button"] {
        background-color: v.$color2;
        border: 1px solid v.$color2;

        &:hover {
            background-color: v.$color6;
            border: 1px solid v.$color6;
        }
    }

    button.btn {
        &:hover {
            background-color: v.$color6;
            border: 1px solid v.$color6;
        }
    }
    .admin-vehicles-table-container {
        width: 100%;
        border-radius: 5px;
        box-shadow: 0 0 10px v.$color1;
        [role="row"] {
            cursor: pointer;
        }

        > div {
            &:nth-child(1) {
                border-radius: 5px 5px 0 0;
                background-color: v.$color1;
                color: v.$color4;
                text-transform: uppercase;
                font-weight: 700;
            }

            &:nth-child(2) {
                [role="table"] {
                    [role="rowgroup"] {
                        &:nth-child(1) {
                            [role="row"] {
                                border-bottom: 1px solid v.$color1;
                                text-transform: uppercase;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            &:nth-child(3) {
                nav {
                    border-top: 1px solid v.$color1;
                    border-radius: 0 0 5px 5px;
                }
            }
        }
    }
}
