@use "../../../../assets/styles/scss/variables" as v;
@use "../../../../assets/styles/scss/mixins" as m;

.popular-vehicles {
    [class^="col-"]:nth-child(2n) {
        .vehicle-card {
            .image {
                background-image: linear-gradient(-65deg, v.$color3, v.$color1);
            }
        }
    }
}
