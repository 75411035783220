@use "../../../../assets/styles/scss/variables" as v;
@use "../../../../assets/styles/scss/mixins" as m;

.vehicle-card {
    height: 100% !important;
    border: 1px solid v.$color4;
    text-align: center;
    color: v.$color1;
    @include m.flexbox(column, space-between, center);
    border-radius: 5px 5px 0 0;

    .image {
        background-image: linear-gradient(65deg, v.$color6, v.$color1);
        border-radius: 5px 5px 0 0;
        min-height: 275px;
        @include m.flexbox(row, center, center);

        img {
            border-radius: 5px 5px 0 0;
        }
    }

    h4 {
        font-weight: 400;
        padding: 1rem;
        color: v.$color2;
    }

    a {
        width: 100%;
        color: v.$color4;

        border-radius: 0 0 5px 5px;
    }

    .details {
        @include m.flexbox();
        flex-wrap: wrap;

        > div {
            @include m.flexbox(row, center, center, 0.5rem);
            padding: 0.7rem;
            flex: 1 0 50%;
            border-top: 1px solid v.$color4;
            border-right: 1px solid v.$color4;
            white-space: nowrap;

            svg {
                color: v.$color2;
            }
        }
    }
}
