@use "../../../../assets/styles/scss/variables" as v;
@use "../../../../assets/styles/scss/mixins" as m;

.admin-new-vehicle-form {
    box-shadow: 0 0 5px v.$color1;
    border-radius: 5px;
    padding: 2rem;

    .image-area {
        background-color: v.$color4;
        box-shadow: 0 0 5px v.$color1;
        border-radius: 5px;
        position: relative;
        z-index: 1;
        min-height: 200px;
        @include m.flexbox(row, center, center);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 5px;
        }

        .cover {
            @include m.flexbox(row, center, center);
            position: absolute;
            inset: 0;
            background-color: rgba(134, 134, 134, 0.3);
            opacity: 0;
            z-index: 100;
            transition: all 0.3s ease-in-out;

            label.form-label {
                background-color: v.$color1;
                color: v.$color4;
                border: 1px solid v.$color1;

                &:hover {
                    background-color: v.$color2;
                    border: 1px solid v.$color2;
                }
            }
        }

        &:hover {
            .cover {
                opacity: 1;
            }
        }

        .image-error {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
        }

        @media (max-width: 1200px) {
            margin-bottom: 2rem;
        }
    }
}
