@use "../../../assets/styles/scss/variables" as v;
@use "../../../assets/styles/scss/mixins" as m;

.page-header {
    @include m.bg-image("../../../assets/images/about/bg.jpg");
    @include m.flexbox($justify-content: center, $align-items: flex-end);
    height: 300px;
    padding-bottom: 2rem;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background-color: v.$color3;
        opacity: 0.4;
    }

    h1 {
        color: v.$color1;
        z-index: 100;
        font-size: 4rem;
        font-weight: 700;
        text-transform: uppercase;
        text-shadow: 0 0 3px v.$color6;

        @media (max-width: 600px) {
            font-size: 3rem;
        }

        @media (max-width: 500px) {
            font-size: 2.5rem;
        }

        @media (max-width: 400px) {
            font-size: 2rem;
        }
    }
}
