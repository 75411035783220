@use "../../../../assets/styles/scss/variables" as v;
@use "../../../../assets/styles/scss/mixins" as m;

.best-offers {
    .offers {
        position: relative;
        padding: 6rem 0;

        &::after {
            content: "";
            position: absolute;
            inset: 0;
            @include m.bg-image(
                "../../../../assets/images/about/best-offer.png"
            );
            opacity: 0.1;
            z-index: -1;
        }

        [class^="col-"] {
            @include m.flexbox(column, space-between, center, 2rem);
        }

        [class^="col-"]:nth-child(2) {
            justify-content: center;

            @media (max-width: 992px) {
                transform: rotate(90deg);

                img {
                    width: 35vw;
                }
            }
        }
    }
}
