@use "../../../../assets/styles/scss/variables" as v;
@use "../../../../assets/styles/scss/mixins" as m;

.details-accordion {
    tr {
        td {
            &:first-child {
                text-transform: uppercase;
                font-weight: 500;
            }
        }
    }
}
