// LIBRARIES
@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "bootstrap/scss/bootstrap";
@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";
@import "swiper/css/effect-fade";
@import "sweetalert2/src/variables";
@import "sweetalert2/src/sweetalert2";

// STYLES

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body,
#root {
    height: 100%;
}

a {
    text-decoration: none !important;
}
