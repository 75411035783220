@use "../../../../assets/styles/scss/variables" as v;
@use "../../../../assets/styles/scss/mixins" as m;

.admin-reservation-details-page {
    margin-top: 2rem;

    h2 {
        text-transform: uppercase;
        color: v.$color1;
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }

    .buttons-container {
        @include m.flexbox(row, space-between, center);
        flex-wrap: wrap;
        text-transform: uppercase;
        .go-to-customer {
            a[role="button"] {
                @include m.button(v.$color6, v.$color1, v.$color2, v.$color4);
            }
        }
        .btn-group {
            button.btn {
                text-transform: uppercase;
                &:nth-child(odd) {
                    @include m.button(
                        v.$color1,
                        v.$color4,
                        v.$color2,
                        v.$color4
                    );
                }
                &:nth-child(2) {
                    @include m.button(
                        v.$color6,
                        v.$color1,
                        v.$color2,
                        v.$color4
                    );
                }
            }

            @media (max-width: 400px) {
                @include m.flexbox(column, center, center, 5px);
                * {
                    border-radius: 5px !important;
                }
            }
        }

        @media (max-width: 550px) {
            @include m.flexbox(column, center, center, 1rem);

            * {
                width: 100%;
            }
        }
    }
}
