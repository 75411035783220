@use "../../../assets/styles/scss/mixins" as m;
@use "../../../assets/styles/scss/variables" as v;

.error-page {
    text-align: center;
    min-height: 97vh;
    position: relative;
    color: v.$color1;

    @include m.flexbox(column, center, center, 0);

    .error-code {
        @include m.absoluteCenter;
        font-size: 30vw;
        font-weight: 800;
        z-index: -1;
        color: v.$color1;
        opacity: 0.07;
    }
}
