@use "../../../assets/styles/scss/variables" as v;
@use "../../../assets/styles/scss/mixins" as m;

.admin-carousel {
    margin-top: 5rem;
    border: 1px solid v.$color6;
    border-radius: 5px;

    h2 {
        text-align: center;
        background-color: v.$color3;
        color: v.$color4;
        padding: 1rem 0;
        margin: 0 20px;
    }

    img {
        margin: auto;
    }

    .carousel-item {
        > div {
            min-height: 333px;
            @include m.flexbox(row, center, center);
        }
    }

    .carousel-indicators {
        button {
            background-color: v.$color3;
        }
    }

    .carousel-control-next,
    .carousel-control-prev {
        background-color: v.$color6;
        color: v.$color6;
        opacity: 1;
        width: 20px;
    }
}
